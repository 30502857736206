import app from "app/Application";
import Module from "modules/Module";
import { throttle, debounce } from 'throttle-debounce';


import $ from "jquery";

export default class Navigation extends Module {

    constructor($scope) {
        super($scope);
        this._open = false;

        this.switchNavThrottle = throttle(150, (e) => this.switchNav(e));
    }

    switchNav(e) {
        if(e.srcElement.innerWidth > 991){
            this.toggleNav();
            window.removeEventListener('resize', this.switchNavThrottle);
        }
    }



    toggleNav() {
        if (this._open) {
            $('body').removeClass('modal-open');
            $('.nav-sidebar').removeClass('show');
            $('.navbar-toggler').removeClass('active');
            this._open = false;
        } else {
            window.addEventListener('resize', this.switchNavThrottle);

            $('body').addClass('modal-open');
            $('.nav-sidebar').addClass('show');
            $('.navbar-toggler').addClass('active');
            this._open = true;
        }
    }

    start($scope) {
        $('.navbar-toggler').on('click', () => this.toggleNav())
        this.initStickyHeader();
    }

    initStickyHeader() {
        let $header = $(".js-page-header"),
            lastScroll = 0;

        window.addEventListener("scroll", translateHeader)


        function translateHeader() {
            if (window.matchMedia('(min-width: 991px)').matches) {
                if (window.pageYOffset > 40 && window.pageYOffset > lastScroll) {
                    if (!$header.hasClass("transformed")) {
                        $header.addClass("transformed");
                    }
                } else if (window.pageYOffset < lastScroll) {
                    $header.removeClass("transformed");
                }
                lastScroll = window.pageYOffset;
            }
        }


    }
};


app.registerModule("Navigation", Navigation, true);