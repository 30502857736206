import app from "app/Application";
import Module from "modules/Module";
import 'slick-carousel';
import $ from "jquery";

export default class PreviewSlides extends Module {
    constructor($scope){
        super($scope);

        this._sliderOptions = {
            infinite : false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoPlay: false,
            responsive: [
                {
                    breakpoint: 1111,
                    settings: {
                        arrows: false
                    }
                }
            ]
        };

    }

    initSlider($scope){
        $scope.slick(this._sliderOptions)
    }

    start($scope) {
        this._$sliders  = $scope.find('.preview-slides__slider');

        this._$sliders.each((_,el) => {
            this.initSlider($(el))
        })

        $('.js-preview-slides__tab').on('shown.bs.tab', function (e) {
            const $tabcontent = $($(e.target).attr('href'))
            $tabcontent[0].slick.refresh()

            var disp = $tabcontent[0].style.display;
            $tabcontent[0].style.display = 'none';
            var trick = $tabcontent[0].offsetHeight;
            $tabcontent[0].style.display = disp;
        })
    }
};


