import app from "app/Application";
import Module from "modules/Module";

import $ from "jquery";

import Navigation from "modules/Navigation";
import StickyHeader from "modules/StickyHeader";

export default class Header extends Module {

    start($scope) {
        new Navigation($scope).start($scope);
        // new StickyHeader($scope).start($scope);
    }
};

