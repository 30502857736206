import app from "app/Application";
import Module from "modules/Module";
import $ from "jquery";
import scrollingTabs from "jquery-bootstrap-scrolling-tabs"

export default class ScrollingTabs extends Module {

    start($scope) {
        const
            $tabs = $('.js-scrolling-tabs');

        $tabs.each((_,el) => {
            const $tab = $(el);

            $tab.scrollingTabs({
                bootstrapVersion: 4,
                scrollToTabEdge: true ,
                disableScrollArrowsOnFullyScrolled: true,
                widthMultiplier: 1,
                enableSwiping: true,
                leftArrowContent: [
                    '<div class="scrtabs-tab-scroll-arrow scrtabs-tab-scroll-arrow-left">',
                        '<div class="h-100 w-100 d-flex justify-content-center align-items-center">',
                            '<i class="icon icon-prev"></i>',
                        '</div>',
                    '</div>'
                ].join(''),
                rightArrowContent: [
                    '<div class="scrtabs-tab-scroll-arrow scrtabs-tab-scroll-arrow-right">',
                        '<div class="h-100 w-100 d-flex justify-content-center align-items-center">',
                            '<i class="icon icon-next"></i>',
                        '</div>',
                    '</div>'
                ].join('')

            });
        })

    }

};


