import app from "app/Application";
import Module from "modules/Module";
import 'slick-carousel';
import videojs from "video.js";

import $ from "jquery";

export default class ContentSlider extends Module {

    constructor($scope){
        super($scope);

        this._$sliders       = null;
        this._slider        = [];
        this._sliderOptions = {
            infinite : false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            nextArrow: '<button type="button" class="btn-icon slick-arrow slick-next" aria-label="Next"><i class="icon icon-next"></i></button>',
            prevArrow: '<button type="button" class="btn-icon slick-arrow slick-prev" aria-label="Previous"><i class="icon icon-prev"></i></button>',
            responsive: [
                {
                    breakpoint: 1111,
                    settings: {
                        arrows: false
                    }
                }
            ]
        };
    }

    initSlider($scope){
        let $slides = $scope.find('.js-contentSlider__slide');
        let videos = [];
        let videosMobile = [];

        $scope.on('init', (e)=>{
            if($scope.find('.js-video_js').length){
                $slides.each((_,el) => {
                    let $videoSlide = $(el).find('.js-video_js')
                    if($videoSlide.length){
                        videos[_] = this.initVideo($videoSlide);
                        if(_ === 0){
                            videos[_].ready(() => {
                                window.requestAnimationFrame(() => {
                                    videos[_].play();
                                })
                            });
                        }
                    }
                    let $videoSlideMobile = $(el).find('.js-video_js-mobile')
                    if($videoSlideMobile.length){
                        videosMobile[_] = this.initVideo($videoSlideMobile);
                        if(_ === 0){
                            videosMobile[_].ready(() => {
                                window.requestAnimationFrame(() => {
                                    videosMobile[_].play();
                                })
                            });
                        }
                    }
                })
            }
        })

        $scope.slick(this._sliderOptions).on('afterChange', function (event, slick, currentSlide) {
            // preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
        });

        $scope.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
            let newSlideNum = nextSlide + 1;
            let $nextSlide = $(slick.$slides.get(nextSlide))
            let $currentSlide = $(slick.$slides.get(currentSlide));

            if($currentSlide.find('.js-video_js').length){
                videos[currentSlide].pause();
                videosMobile[currentSlide].pause();
            }

            if($nextSlide.find('.js-video_js').length){
                if (window.matchMedia('(min-width: 767px)').matches) {
                    videos[nextSlide].currentTime(0);
                    videos[nextSlide].play();

                }else{
                    console.log(videosMobile[nextSlide], nextSlide)
                    videosMobile[nextSlide].currentTime(0);
                    videosMobile[nextSlide].play();
                }
            }


        })


        // onEnterViewPort($slider, function ($slider) {
        //     preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
        // });
    }

    //init for all video Elements
    initVideo($video){
        let id = $video.attr('id');
        let player = videojs(id);
        let btn = $video.parent().find(".vjs-big-play-button");
        btn.empty();
        btn.addClass('icon icon-play-button');
        player.ready(() => {
            window.requestAnimationFrame(() => {
                if($video.data('autoplay')){
                    player.play();
                }
            })
        })
        return player;
    }



    start($scope) {
        this._$sliders   = $scope.find('.js-contentSlider')

        this._$sliders.each((_, el) => {
            this._slider = this.initSlider($(el).find('.js-contentSlider__slider'));
        })
    }

};


