'use strict'

import app from "app/Application";
import Module from "modules/Module";
import slick from "slick-carousel"

import $ from "jquery";

export default class ScrollingTabs extends Module {

    start($scope) {
        const $slider = $scope.find('.js-pds-slider__slider');

        $slider.slick({
            dots: true,
            arrows: false
        })
    }
}