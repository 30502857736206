import app from "app/Application";
import Module from "modules/Module";


import {getPrefixedDataSet} from "./elements/data-set-utils";
import {isParsleyForm, isValid} from "./elements/parsley-bootstrap-validation";
import asyncAppend from './elements/async-append';

import {getElementBySelector, getElementsBySelectorObject} from "./utilities/normalizeSelector";
import fetch from './utilities/fetch'; // IE10 Polyfill

import 'url-polyfill';
import 'url-search-params-polyfill'; // Edge Polyfill
import { debounce } from "debounce";

import $ from "jquery";

export default class AjaxForm extends Module {

    constructor(props) {
        super(props);
        this.defaultSelectors = {
            base: '.js-ajax-form',
            result: '.js-ajax-form__result',
            loading: '.js-ajax-form__loading',
            notifications: '.js-ajax-form__notifications',
            form: '.js-ajax-form__form',
            additionalForm: '.js-ajax-form__additional-form',
            errorArea: '.js-ajax-form__error-area',
            retry: '.js-ajax-form__retry',
            link: '.js-ajax-form__link'
        };

        this.defaultOptions = {
            submitOnChange: false,
            addUrlParams: false
        };


    }

    createInitInScope(options = this.defaultOptions, selectors = this.defaultSelectors) {
        return function ($scope) {
            return getElementBySelector(selectors.base, $scope).each(function () {
                this.createAjaxForm($(this), selectors, options);
            });
        }
    }

    addSearchParamsToUrl(url, searchParams) {
        url = new URL(url, location.origin);

        let searchParamsArray = Array.from(searchParams);
        searchParamsArray.forEach(([name]) => url.searchParams.delete(name));
        searchParamsArray.forEach(([name, value]) => url.searchParams.append(name, value));

        return url;
    }


    createAjaxForm($baseElement, selectors = this.defaultSelectors, options = this.defaultOptions) {
        let $elements = getElementsBySelectorObject(selectors, $baseElement);

        let pendingRequest = null;

        options = {
            ...this.defaultOptions,
            ...options,
            ...getPrefixedDataSet('ajax-form', $baseElement)
        };

        $elements.form.on('submit', (evt) =>{
            if (pendingRequest && pendingRequest.abort) {
                pendingRequest.abort();
                pendingRequest = null;
            }

            evt.preventDefault();

            if (isParsleyForm($elements.form) && !isValid($elements.form)) {
                return;
            }

            $baseElement.trigger('submit.ajax-form');

            let action = $elements.form.data('action') || $elements.form.attr('action');
            let method = $elements.form.data('method') || $elements.form.attr('method');
            let formData = new FormData($elements.form[0]);
            let params = new URLSearchParams(formData);

            let url = new URL(location.href);
            url.searchParams.delete('page');
            url = this.addSearchParamsToUrl(url, params);

            pendingRequest = load(action, method, params, url);

            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error, requestState) => {
                    if (error.name !== 'AbortError' // native fetch abort
                        && requestState !== 'abort') { // jquery abort
                        pendingRequest = null;
                    }
                });
        });

        if (options.submitOnChange) {
            $elements.form.on('change', debounce(() => $elements.form.trigger('submit'), 200));
            // $elements.additionalForm.on('change', debounce(() => $elements.additionalForm.trigger('submit'), 200));
        }

        $elements.retry.on('click', function (evt) {
            evt.preventDefault();

            if (lastLoadParams) {
                load(...lastLoadParams);
            }
        });

        addLinkClickHandler(getElementBySelector(selectors.link, $baseElement));

        function addLinkClickHandler($links) {
            $links.on('click', function (evt) {
                evt.preventDefault();

                let href = $(this).attr('href') || $(this).data('href');
                let action = $elements.form.data('action') || $elements.form.attr('action');
                let params = new URL(href, location.origin).searchParams;

                pendingRequest = load(action, 'GET', params, href);
            });
        }

        let lastLoadParams = null; // for retry

        const load = (url, method = "GET", params, historyUrl) => {
            lastLoadParams = [url, method, params, historyUrl];

            let $elements = getElementsBySelectorObject(selectors, $baseElement);
            $baseElement.trigger('fetch.ajax-form');

            if (options.addUrlParams) {
                history.replaceState(history.state, document.title, historyUrl || url);
            }

            if (method.toUpperCase() === "GET") {
                url = this.addSearchParamsToUrl(url, params);
            }

            let request = fetch(url, {
                method: method,
                ...(method.toUpperCase() !== "GET" ? {
                    body: new URLSearchParams(params)
                } : {})
            });

            let promise = request.then(response => (response.json && typeof response.json === 'function')
                ? response.clone().json()
                : response);

            asyncAppend(
                {$target: $elements.result, $loading: $elements.loading, $notifications: $elements.notifications},
                promise
            )
                .then((result) => {
                    console.log("inside the result? the ", result)
                    $baseElement.trigger('asdf');
                    let content = result.html || result.content;
                    if (content && result.success !== false) {
                        console.log("passt ois", $baseElement)
                        $baseElement.trigger('success.ajax-form');
                        $elements.errorArea.attr('hidden', 'hidden');
                        addLinkClickHandler(getElementBySelector(selectors.link, $baseElement));
                    } else {
                        $baseElement.trigger('failed.ajax-form');
                        $elements.errorArea.attr('hidden', null);
                    }
                    if(result.success && result.history){
                        window.history.pushState(
                            {
                                'element' : $baseElement,
                                'lastLoadParams': lastLoadParams
                            },
                            result.history.title,
                            result.history.url)
                    }
                    if(result.link){
                        setTimeout(() => {
                            window.location.replace(result.link);
                        }, 1500)
                    }
                });

            promise.catch((error, requestState) => {
                if (error.name !== 'AbortError' // native fetch abort
                    && requestState !== 'abort') { // jquery abort
                    console.error(error);
                    $baseElement.trigger('failed.ajax-form');
                    $elements.errorArea.attr('hidden', null);
                }
            });

            return request;
        }

        return $baseElement;


        // let api = {
        //     submit: () => console.log('submit !!!!')
        // };
        //
        // $baseElement.data('ajax-form', api);
        //
        // return api;
    }

    start($scope) {
        this.createAjaxForm($scope)
    }
};


