import app from "app/Application";
import Module from "modules/Module";

import $ from "jquery";

export default class Gallery extends Module {

    start($scope) {
        const galleries = $('.js-gallery');

        console.log(galleries);

        galleries.slick();
    }

};

