import Module from "modules/Module";
import {onEnterViewPort} from "modules/elements/viewport-utils";
import {getPrefixedDataSet} from "modules/elements/data-set-utils";


import $ from "jquery";

export default class LazyLoad extends Module {

    constructor(props) {
        super(props)

        this.defaultOptions = {
            offset: window.innerHeight / 2
        };

    }

    start($scope) {
        // Dynamically import the LazySizes library
        let script = document.createElement("script");
        script.async = true;
        script.src =
            "https://cdnjs.cloudflare.com/ajax/libs/lazysizes/4.1.8/lazysizes.min.js";
        document.body.appendChild(script);

        this.$backgrounds = this.$('.js-lazy__background')

        if (this.$backgrounds.length) {
            this.initBackgrounds();
        }
    }

    initBackgrounds() {
        this.$backgrounds.each((_, el) => {
            const $image = $(el);

            let elementOptions = {
                ...this.defaultOptions,
                ...getPrefixedDataSet('lazy-img', $image)
            };

            onEnterViewPort($image, (img) => {
                this.loadImg($(img));
            }, elementOptions);


        })
    }

    loadImg($img) {
        // background image
        let url = getPrefixedDataSet('lazy-img', $img).background;
        if (url) {
            $img.css('background-image', 'url(' + url + ')');
        }
    }


};


