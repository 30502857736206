// necessary so the vendor chunk is executed
require("vendor");

import $ from "jquery";
import app from "./app/Application";

// if (!window.HTMLPictureElement) {
//     import('picturefill');
// }



//config
let _config = window['_config'] || {};

///////////
// modules
//////////

//singletons
import Header from "modules/Header";
app.registerModule("Header", Header, true);

import Toast from "modules/Toast";
app.registerModule("Toast", Toast, true);

import GoToLink from "modules/GoToLink";
app.registerModule("GoToLink", GoToLink, true);

import PreviewSlides from "modules/PreviewSlides";
app.registerModule("PreviewSlides", PreviewSlides, true);

import BlogteaserSlider from "modules/BlogteaserSlider";
app.registerModule("BlogteaserSlider", BlogteaserSlider, true);

import Editors from "modules/forum/Editor";
app.registerModule("Editor", Editors, true);

import AjaxFormHistory from "modules/forms/AjaxFormHistory";
app.registerModule("AjaxFormHistory", AjaxFormHistory, true);

import { AjaxModal } from "modules/modal/AjaxModal";
app.registerModule("AjaxModal", AjaxModal, true);

import { CodeHighlights } from "modules/utilities/highlightCode";
app.registerModule("CodeHighlights", CodeHighlights, true);

import { Cookiebar } from "modules/Cookiebar";
app.registerModule("Cookiebar", Cookiebar, true);









import { CookiebarModal } from "modules/Cookiebar";
app.registerModule("CookiebarModal", CookiebarModal, false);

import { CodeHighlight } from "modules/utilities/highlightCode";
app.registerModule("CodeHighlight", CodeHighlight, false);

import { AddEditor } from "modules/forum/Editor";
app.registerModule("AddEditor", AddEditor, false);

import Dropzones from "modules/forms/Dropzone";
app.registerModule("Dropzone", Dropzones, false);

import { AddAjaxModalLinks } from "modules/modal/AjaxModal";
app.registerModule("AddAjaxModalLinks", AddAjaxModalLinks , false);

import Timeago from "modules/utilities/Timeago";
app.registerModule("Timeago", Timeago, false);

import ForumAdmin from "modules/forum/ForumAdmin";
app.registerModule("ForumAdmin", ForumAdmin, false);

import RatingUpDown from "modules/forum/RatingUpDown";
app.registerModule("RatingUpDown", RatingUpDown, false);

import Select from "modules/forms/select";
app.registerModule("Select", Select, false);

import TagsInput from "modules/forms/TagsInput";
app.registerModule("TagsInput", TagsInput, false);


import Parsley from "modules/elements-wrapper/Parsley";
app.registerModule("Parsley", Parsley, false);



import BirthdayPicker from "modules/forms/BirthdayPicker";
app.registerModule("BirthdayPicker", BirthdayPicker, false);



import { CartDrawer, CartDrawerTriggers }from "modules/shop/CartDrawer";
app.registerModule("CartDrawer", CartDrawer, true);
app.registerModule("CartDrawerTriggers", CartDrawerTriggers, false);

import { PdsFormInit, PdsForm }from "modules/shop/PdsForm";
app.registerModule("PdsForm", PdsForm, false);
app.registerModule("PdsFormInit", PdsFormInit, true);

import { InitCartBadge,  CartBadge }from "modules/shop/CartBadge";
app.registerModule("InitCartBadge", InitCartBadge, true);
app.registerModule("CartBadge", CartBadge, false);

//rest
import ContentSlider from "modules/ContentSlider";
app.registerModule("ContentSlider", ContentSlider, false);

import ScrollingTabs from "modules/ScrollingTabs";
app.registerModule("ScrollingTabs", ScrollingTabs, false);

import Gallery from "modules/Gallery";
app.registerModule("Gallery", Gallery);

import PdsSlider from "modules/PdsSlider";
app.registerModule("PdsSlider", PdsSlider);

import OverflowBros from "modules/OverflowBros";
app.registerModule("OverflowBros", OverflowBros, false);

import AjaxForm from "modules/AjaxForm";
app.registerModule("AjaxForm", AjaxForm, false);

import SubmitForm from "modules/utilities/SubmitForm";
app.registerModule("SubmitForm", SubmitForm, false);

import AutoSubmitForm from "modules/utilities/AutoSubmitForm";
app.registerModule("AutoSubmitForm", AutoSubmitForm, false);

import AddToCart from "modules/shop/AddToCart";
app.registerModule("AddToCart", AddToCart, false);

import LazyLoad from "modules/LazyLoad";
app.registerModule("LazyLoad", LazyLoad, false);

import { spinnerInit } from "modules/forms/Numberspinner";
app.registerModule("NumberSpinner", spinnerInit, false);

import PwCheck from "modules/forms/PwCheck";
app.registerModule("PwCheck", PwCheck, false);

import ProductgridHeader from "modules/shop/productgrid/productgrid-header";
app.registerModule("ProductgridHeader", ProductgridHeader, false);

$(function() {
    app.start();

    const
        $overflows = $('.text-overflow-4, .text-overflow-3');

    if($overflows.length){
        $overflows[0].style.display='none';
        $overflows[0].offsetHeight; // no need to store this anywhere, the reference is enough
        $overflows[0].style.display='';
    }

});
