import app from "app/Application";
import Module from "modules/Module";
import { throttle, debounce } from 'throttle-debounce';
import 'slick-carousel';

export default class BlogteaserSlider extends Module {

    constructor($scope){
        super($scope);

        this._sliderOptions = {
            infinite : false,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoPlay: false,
            mobileFirst: true,
            nextArrow: '<button type="button" class="btn-icon slick-arrow slick-next" aria-label="Next"><i class="icon icon-next"></i></button>',
            prevArrow: '<button type="button" class="btn-icon slick-arrow slick-prev" aria-label="Previous"><i class="icon icon-prev"></i></button>',
        };

        this._resizeHandler = throttle(150, ($scope) => this.resizeHandler($scope));
    }

    resizeHandler($slider){
        $(window).on('load resize orientationchange', () => {
            if(window.innerWidth > 767) {
                if ($slider.hasClass('slick-initialized')) {
                    $slider.slick('unslick');
                }
                return
            }
            if (!$slider.hasClass('slick-initialized')) {
                return $slider.slick(this._sliderOptions);
            }
        });
    }

    initSlider($scope) {
        this._resizeHandler($scope);
        if(window.innerWidth > 767){
            return;
        }
        $scope.slick(this._sliderOptions);
    }

    start($scope) {
        this._$sliders  = $scope.find('.js-blogteaser-slider');

        this._$sliders.each((_,el) => {
            this.initSlider($(el))
        })
    }



};


