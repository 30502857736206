import Module from "./Module";
import app from "../app/Application";
import fetch from "./utilities/fetch";
import {addLoadingContainer, removeLoadingContainer} from "./utilities/loading";
import {showNotification} from "./elements/alert-notification";


export class Cookiebar extends Module{

    start(){
        this.modalRequest       = null;
        this.acceptAllRequest   = null;
        this.cookiebar          = $('body').find('.js-cookiebar')
        this.ajaxModal          = $('body').find('.js-ajax-modal');
        this.ajaxModalContent   = $('body').find('.js-ajax-modal__content');
        this.cookiebarSubmitBtn = this.cookiebar.find('.js-cookiebar__submitBtn');
        this.cookiebarMoreBtn   = this.cookiebar.find('.js-cookiebar__moreBtn')

        if(this.cookiebar.length){
            this.cookiebarSubmitBtn.on('click', () => {
                if(this.acceptAllRequest){
                    this.acceptAllRequest.abort();
                    this.acceptAllRequest = null;
                }

                addLoadingContainer(this.cookiebarSubmitBtn,[],false)

                this.acceptAllRequest = fetch(app.getConfig('cookiebarAcceptAllUrl'));
                showNotification(this.acceptAllRequest);
                this.acceptAllRequest.then((res) => {
                    return res.clone().json()
                }).then((res) => {
                    if(res.success){
                        this.hideCookieBar()
                        this.initCookieFunctions()
                    }
                }).finally(() => {
                    removeLoadingContainer(this.cookiebarSubmitBtn)
                })
            });
        }

        if(this.cookiebarMoreBtn.length){
            this.cookiebarMoreBtn.on('click', () => {
                this.showCookieModal();
            })
        }
    }

    hideCookieBar(){
        this.cookiebar.remove()
    }

    showCookieModal(){
        if(this.modalRequest){
            this.modalRequest.abort();
            this.modalRequest = null;
        }

        this.ajaxModalContent.empty();
        this.ajaxModal.modal('show');

        addLoadingContainer(this.ajaxModalContent,[],true)

        this.modalRequest = fetch(
            app.getConfig('cookiebarModalUrl')
        ).then((res) => {
            return res.clone().json()
        }).then((res) => {
            if(res.success && res.html){
                this.ajaxModalContent.append(res.html);
                app.startModules(this.ajaxModalContent, res.jsModules);
            }
        }).finally(() => {
            removeLoadingContainer(this.ajaxModalContent)
            this.modalRequest = null;
        }).catch((error) => {
            this.ajaxModal.modal('hide');
            console.error(error)
        })
        // showNotification(this.modalRequest);
    }

    hideCookieModal(){
        this.hideCookieBar();
        this.ajaxModal.modal('hide')
    }

    initCookieFunctions(){
        //todo if needed
    }
}

export class CookiebarModal extends Module {
    start($scope){
        this.ajaxForm   = $scope.hasClass('js-ajax-modal__content') ? $scope : this.$('.js-ajax-modal__content');
        this.ajaxForm.on('success.ajax-form', () => {
            app.getSingleton('Cookiebar').initCookieFunctions();
            app.getSingleton('Cookiebar').hideCookieModal();
        })
    }
}