import app from "app/Application";
import Module from "modules/Module";

import $ from "jquery";

export default class StickyHeader extends Module {
    changeWindowSize(isDesktop) {
        if(isDesktop){
            $(window).on('scroll', this.stickyheader);
            $('body').css('overflow', 'auto');
        }else{
            $(window).off('scroll', this.stickyheader);
            this._$subHeader.removeClass("transformed");
            this._$searchbar.removeClass("transformed");
        }
    }

    stickyheader() {
        if (window.pageYOffset > this._lastScroll) {
            if(!this._$subHeader.hasClass("transformed")){
                this._$subHeader.addClass("transformed");
                this._$searchbar.addClass("transformed");
            }
        } else if(window.pageYOffset < this._lastScroll) {
            this._$subHeader.removeClass("transformed");
            this._$searchbar.removeClass("transformed");
        }
        this._lastScroll = window.pageYOffset;
    }

    start($scope) {
        this._$subHeader = $(".js-header__bottomElement"),
        this._lastScroll = 0,
        this._$searchbar = $(".js-search--mobile-wrapper");


        let x = window.matchMedia("(min-width: 992px)");
        this.changeWindowSize(x.matches);
        x.addListener(function () {
            this.changeWindowSize(x.matches);
        });
    }
};


app.registerModule("StickyHeader", StickyHeader, true);
