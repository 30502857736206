import app from "app/Application";
import Module from "modules/Module";

import $ from "jquery";

export default class GoToLink extends Module {

    start() {
        $('body').on('click', '.js-go-to-link', function (evt) {
            let $target = $(evt.target);

            if (!$target.is('a, button, input, select, label')
                && !$target.closest('a, button, input, select, label').length
            ) {
                let link = $(this).find('.js-go-to-link__link:first');
                if (!link.length) {
                    link = $(this).find('a:first');
                }
                let href = link.attr('href');

                if (window.getSelection().toString()) {
                    // make links selectable - so do nothing if a selection was made
                    evt.preventDefault();
                    evt.stopImmediatePropagation();
                } else if (href) {
                    if (link[0]) {
                        try {
                            link[0].dispatchEvent(new MouseEvent(evt.originalEvent.type, evt.originalEvent));

                            // allow crtl + click on the whole .js-go-to-link element.
                            // this works only for some browsers (eg: Chrome, Edge)
                            // but does not break anything for others (eg: IE, Firefox)
                        } catch (e) { // IE 11 fallback
                            link[0].click();
                        }
                    }

                    evt.preventDefault();
                    evt.stopImmediatePropagation();
                }
            }
        });

    }

};


