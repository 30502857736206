import app from "app/Application";
import Module from "modules/Module";

import $ from "jquery";

export default class OverflowBros extends Module {
    start($scope) {
        this._$container = $scope.find('.js-overflow-bros')

        this._$container.each((_, container) => {
            const $container = $(container);
            const $bros = $container.find('.js-overflow-bros__bro')
            const broCount = $container.children().length
            const bro = $bros.eq(0).innerWidth()
            const broSize = broCount * bro
            let $edgeBro, broOffset, lostBros;

            $(window).on('load resize orientationchange', () => {
                if($container.innerWidth() < broSize){
                    if((broSize - $container.innerWidth()) != broOffset){
                        if($edgeBro){
                            $edgeBro.find('.js-overflow-bros__overlay').remove();
                        }
                        broOffset = broSize - $container.innerWidth()
                        lostBros = Math.ceil(broOffset / bro)

                        $edgeBro = $($bros[broCount - lostBros - 1])

                        $edgeBro.prepend(
                            '<div class="js-overflow-bros__overlay overflow-bros__overlay p-1 strong"><span class="z-index-1">+'
                            + parseInt(lostBros + 1) +
                            '</span></div>')

                    }
                }else{
                    if($edgeBro){
                        $edgeBro.find('.js-overflow-bros__overlay').remove();
                        $edgeBro = undefined;
                    }
                }
            })
        })
    }

};


